import _ from "lodash";
/**
 * Concatenates the rootPath and the field path to give the full path of field in form.
 * @param {Array<String|Number|RegExp>} rootPath Root path of fields in a given react component. This component is part of the component that
 * contains the form. Its items can be regular expressions.
 * @param {Array<String|Number|RegExp>} fieldPath Field path expressed as a part of the path that does not contain the root path.
 * Its items can be regular expressions.
 * @returns {Array<String|Number|null>} The field full path in form, given as an array of strings. Return null if some of the arguments
 * is not of type array.
 */
export const getFieldPath = (rootPath, fieldPath) => {
  if (!Array.isArray(rootPath) || !Array.isArray(fieldPath)) {
    const errorMessage =
      "Error: Invalid argument(s) given as input to function getFieldPath. They must be of array type!" +
      "  ---  " +
      "Is rootPath an array?  " +
      Array.isArray(rootPath).toString() +
      "  ---  " +
      "Is fieldPath an array? " +
      Array.isArray(fieldPath).toString();

    throw new Error(errorMessage);
  }

  return [...rootPath, ...fieldPath];
};

/**
 * Get the field's path as a string, where each item of field's path is separated by '_'.
 *@param {Array<String|Number|RegExp>} fieldPath Path of field in form.
 * @returns {String|null} The field path as a string. If fieldPath is not an array then it returns null.
 */
export const getFieldPathString = (fieldPath) => {
  return fieldPath.join("_");
};

/**
 * Test if the path of a given field is the same as the path of the changedField.
 * The test is made using the method test() of a RegExp instance. The RegExp instance is created from the fieldPath argument.
 * @param {Array<String|Number|RegExp>} fieldPath The path of the field being tested. It can contain regular expressions.
 * @param {Array<String|Number|RegExp>} changedFieldPath The path of the changedField.
 * @returns {Boolean|null} True if the paths are the same, false if not. If some of the arguments is not an array then it returns null.
 */
export const getIsFieldChanged = (fieldPath, changedFieldPath) => {
  if (!Array.isArray(changedFieldPath) || !Array.isArray(fieldPath))
    return null;

  const fieldPathString = getFieldPathString(fieldPath);

  const regExpFieldPathString = new RegExp("^" + fieldPathString + "$");

  const changedFieldPathString = getFieldPathString(changedFieldPath);

  return regExpFieldPathString.test(changedFieldPathString);
};

//TODO: Elaborate a set of test to validate the function getDeepestPath. It is the root
//for the quality of changedField logic in DataAnalysisForm component

// This function assumes that the input object is not empty and does not contain circular references
export const getDeepestPath = (obj) => {
  //function to convert key of type string representing a number into a type number
  const formatKey = (key) => {
    const regExp = new RegExp("^[0-9]{1,5}$");

    if (regExp.test(key)) {
      return +key;
    } else {
      return key;
    }
  };
  // Initialize an array to store the current path
  let path = [];
  // Initialize a variable to store the current value
  let value = obj;

  // Loop until the value is not an object or an array
  while (typeof value === "object" && value) {
    //if value is an array and every item that it contains is not an object then return path
    if (
      Array.isArray(value) &&
      value.every((item) => typeof item !== "object")
    ) {
      return path;
    }

    // Get the keys of the value
    let keys = Object.keys(value);
    // If there is only one key, append it to the path and update the value
    if (keys.length === 1) {
      path.push(formatKey(keys[0]));
      value = value[keys[0]];
    } else {
      // If there are more than one keys, find the first key that has more than one subkey
      let key = _.findKey(value, (v) => {
        return typeof v === "object" && v && Object.keys(v).length > 1;
      });
      // If such a key exists, append it to the path and return it
      if (key) {
        path.push(formatKey(key));
        return path;
      } else {
        // If no such key exists, return null as the path is not unique
        return path;
      }
    }
  }
  // Return the path as an array
  return path;
};

/**
 * This function takes the error generated by the catch(err) part of a try-catch
 * statement and returns an errorMsg related to the form list item where
 * it was generated, when validating such a field.
 * @param {*} rootPath
 * @param {*} name
 * @param {*} field
 * @param {*} error
 * @param {*} intl
 * @returns
 */
export const getCodeErrorMsg = (fieldPathString, error, intl) => {
  const errorMsg = intl.formatMessage(
    { id: "errorMsg.validating-field" },
    { fieldPathString: fieldPathString }
  );
  console.log(errorMsg);
  console.log(error);

  return errorMsg;
};

/**
 * This function checks if the X variable of the data series 0 of a given analysis has a `date` data type.
  It first validates the existence of required arguments: form, selectedDataZources, and intl.  
  Next, it determines the actual X variable and the data source ID to which it belongs.
  Using the data source ID, it finds the corresponding data source from the selectedDataZources array.
  After finding the data source, it uses the data schemas of this source to identify the data type 
  of the X variable.
  Finally, it checks if the identified data type is one of the allowed date types.
  Returns true if the X variable is of a date type, false otherwise.
 * @param {*} variable 
 * @param {*} selectedDataZources
 * @param {*} form 
 * @param {*} intl 
 * @returns 
 */
export const isXVariableOfDateType = (
  variable,
  selectedDataZources,
  form,
  intl
) => {
  if (
    !variable ||
    !selectedDataZources ||
    selectedDataZources?.length === 0 ||
    !form ||
    !intl
  )
    return false;

  const getVariableAndDataZourceInfo = () => {
    const dataZourceId = form.getFieldValue([
      "configEdit",
      "dataSeries",
      0,
      "dataZource",
    ]);

    const xVarCurrentSplitted = variable?.split(".");
    const xVarActual =
      xVarCurrentSplitted.length === 1
        ? xVarCurrentSplitted[0]
        : xVarCurrentSplitted[1];

    const result = {
      xVarActual: xVarActual,
      dataZourceId: dataZourceId,
    };

    return result;
  };

  const { xVarActual, dataZourceId } = getVariableAndDataZourceInfo();

  //`locations` is a dummy variable used to set a variable for BarPlot analysis.
  if (["locations"].includes(xVarActual)) return false;

  //dataZource for current x variable
  const xVarCurrentDataZource = selectedDataZources.filter(
    (item) => item.id === dataZourceId
  );

  //using the dataSchemas to determine the data type of the actual value of x variable
  const { dataSchemas } = xVarCurrentDataZource[0];

  let dataSchemasIndex = 0;
  let index =
    dataSchemas[dataSchemasIndex].columnNormalizedNames.indexOf(xVarActual);

  if (index === -1) {
    dataSchemasIndex = 1;
    index = dataSchemas[dataSchemasIndex]?.columnNormalizedNames.indexOf(
      xVarActual === "date_createdQB" ? "date_created" : xVarActual
    );
  }

  if (index === -1) {
    //issue - the x variable must be matched to any of the values in columnNormalizedNamesAll in any
    //of the dataSchemas
    const columnNormalizedNamesAll = {
      dataSchema0: dataSchemas[0].columnNormalizedNames,
      dataSchema1: dataSchemas?.[1]?.columnNormalizedNames,
    };

    let columnNormalizedNamesAllString =
      "dataSchema0: " + columnNormalizedNamesAll["dataSchema0"].join(", ");
    if (columnNormalizedNamesAll["dataSchema1"]) {
      columnNormalizedNamesAllString =
        columnNormalizedNamesAllString +
        " and " +
        "dataSchema1: " +
        columnNormalizedNamesAll["dataSchema1"].join(", ");
    }

    const errorMsg = intl.formatMessage(
      { id: "FormListVariable.isXVariableOfDateType.xVarActual.notMached" },
      {
        xVarActual: xVarActual,
        columnNormalizedNames: columnNormalizedNamesAllString,
        dataZourceName: xVarCurrentDataZource.name,
      }
    );
    const unexpectedError = intl.formatMessage(
      { id: "Error.unexpectedError.Please.contactAdmin" },
      {
        errorMsg: errorMsg,
      }
    );
    throw new Error(unexpectedError);
  }

  const xVarDataType =
    index !== -1 ? dataSchemas[dataSchemasIndex]?.dataTypes[index] : null;

  //allowed date types for x variable
  const allowedDateTypes = ["DATETIME", "DATE"];

  return allowedDateTypes.includes(xVarDataType);
};
