import axiosInstance from "../../utils/axiosInstance";
import { REPORT_FILE_ACTION_TYPES } from "../typesAction";

export const setLoading = () => {
  return { type: REPORT_FILE_ACTION_TYPES.LOAD_REPORT_FILE };
};

export const clearLoading = () => {
  return { type: REPORT_FILE_ACTION_TYPES.CLEAR_LOAD_REPORT_FILE };
};

export const clearReportFileError = () => {
  return { type: REPORT_FILE_ACTION_TYPES.CLEAR_REPORT_FILE_ERRORS };
};

export const listReportFiles =
  (history, pagination, filters = {}, sorter) =>
  async (dispatch) => {
    try {
      dispatch(setLoading());
      // Prepare all query parameters
      let queryParameters = {};
      Object.keys(filters).map(
        (key) =>
          (queryParameters[key] =
            filters[key] == null ? filters[key] : filters[key][0])
      );
      if (sorter !== undefined) {
        queryParameters["sort"] = sorter["field"];
        queryParameters["order"] =
          sorter["order"] === "descend" ? "desc" : "asc";
      }
      if (pagination !== undefined) {
        queryParameters["offset"] =
          (pagination.current - 1) * pagination.pageSize;
        queryParameters["max"] = pagination.pageSize;
      }
      // Spread the parameters
      const params = {
        ...queryParameters,
      };
      // Send request and get data
      const { data } = await axiosInstance(history, dispatch).get(
        "/reportFile",
        { params }
      );

      // Update state
      dispatch({
        type: REPORT_FILE_ACTION_TYPES.LIST_REPORT_FILE,
        payload: data,
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: REPORT_FILE_ACTION_TYPES.ERROR_REPORT_FILE,
        payload: err.message,
      });
    }
  };

export const addReportFile =
  (history, reportFile, setReportFileCreated) => async (dispatch) => {
    try {
      dispatch(setLoading());
      // Send request and get data
      const { data } = await axiosInstance(history, dispatch).post(
        "/reportFile",
        reportFile,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      // Update state
      dispatch({
        type: REPORT_FILE_ACTION_TYPES.ADD_REPORT_FILE,
        payload: data,
      });
      setReportFileCreated(true);
    } catch (err) {
      console.log(err);
      const error = await err.response.data;
      dispatch({
        type: REPORT_FILE_ACTION_TYPES.ERROR_REPORT_FILE,
        payload: error,
      });
    }
  };

export const getReportFile = (history, id) => async (dispatch) => {
  try {
    dispatch(setLoading());

    // Send request and get data
    const { data } = await axiosInstance(history, dispatch).get(
      `/reportFile/${id}`
    );

    // Update state
    dispatch({
      type: REPORT_FILE_ACTION_TYPES.SHOW_REPORT_FILE,
      payload: data,
    });
    return data;
  } catch (err) {
    console.log(err);
    dispatch({
      type: REPORT_FILE_ACTION_TYPES.ERROR_REPORT_FILE,
      payload: err.message,
    });
  }
};

export const editReportFile =
  (history, id, reportFile, setReportFileCreated) => async (dispatch) => {
    try {
      dispatch(setLoading());

      // Send request and get data
      const { data } = await axiosInstance(history, dispatch).patch(
        `/reportFile/${id}`,
        reportFile,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      // Update state
      dispatch({
        type: REPORT_FILE_ACTION_TYPES.EDIT_REPORT_FILE,
        payload: data,
      });
      setReportFileCreated(true);
    } catch (err) {
      console.log(err);
      const error = await err.response.data;
      dispatch({
        type: REPORT_FILE_ACTION_TYPES.ERROR_REPORT_FILE,
        payload: error,
      });
    }
  };

export const deleteReportFile = (history, id) => async (dispatch) => {
  try {
    dispatch(setLoading());
    // Send request
    await axiosInstance(history, dispatch).delete(`/reportFile/${id}`);
    // Update state
    dispatch({
      type: REPORT_FILE_ACTION_TYPES.DELETE_REPORT_FILE,
      payload: id,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: REPORT_FILE_ACTION_TYPES.ERROR_REPORT_FILE,
      payload: err.message,
    });
  }
};
