import {
  Form,
  Input,
  Radio,
  message,
  Breadcrumb,
  Layout,
  Card,
  Button,
} from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useEffect, useState } from "react";
import {
  addReportFile,
  getReportFile,
  editReportFile,
  clearReportFileError,
} from "../../redux/reportFile/reportFileActions";
import { HomeOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import {
  selectError,
  selectLoading,
  selectCurrentReportFile,
} from "../../redux/reportFile/reportFileSelector";
import { Link } from "react-router-dom";

const { Content, Footer } = Layout;

const ReportFileForm = (props) => {
  //local variables
  const intl = useIntl();
  const [form] = Form.useForm();

  //local states
  const [reportFileCreated, setReportFileCreated] = useState(false);
  const pat = RegExp(
    /\/configuration\/reports\/[0-9]{1,1000}\/reportFiles\/new/
  );
  const [edit, setEdit] = useState(!pat.test(props.location.pathname));
  const [acceptFileExtensions, setAcceptFileExtensions] = useState();

  //Handlers
  //--------

  //form onFinish
  const onFinish = (values) => {
    //for uploading file to server
    const formData = new FormData();
    const files = document.getElementById("fileElem").files;
    if (files.length === 1) {
      const file = files[0];
      formData.append("uploadedFile", file);
    } else {
      return;
    }

    formData.append("filename", values["filename"]);
    formData.append("description", values["description"]);
    formData.append("type", values["type"]);
    formData.append("report", values["report"]);

    if (edit) {
      formData.append("oldFileName", props.reportFile.filename);
      props.editReportFile(
        props.history,
        props.match.params.id,
        formData,
        setReportFileCreated
      );
    } else {
      props.addReportFile(props.history, formData, setReportFileCreated);
    }
  };

  //form onFinishFailed
  const onFinishFailed = (errorInfo) => {
    errorInfo.errorFields.map((error) => message.error(error.errors[0]));
  };

  //managing file input and setting field filename
  const handleFileElemChange = () => {
    const files = document.getElementById("fileElem").files;
    if (files) {
      switch (files.length) {
        case 0:
          form.setFieldsValue({ filename: "" });
          break;
        case 1:
          form.setFieldsValue({ filename: files[0].name });
          break;
        default:
          form.setFieldsValue({ filename: "" });
          message.error(
            intl.formatMessage({ id: "label.reportFiles.error.selecting-file" })
          );
      }
    } else {
      form.setFieldsValue({ filename: "" });
      message.error(
        intl.formatMessage({ id: "label.reportFiles.error.selecting-file" })
      );
    }
  };

  //Effects
  //-------

  //populating form fields
  useEffect(() => {
    if (props.error) {
      const error = () => {
        if (typeof props.error === "string") {
          message.error(props.error).then(props.clearReportFileError());
        } else {
          props.error.errors?.map((errorMessage) =>
            message
              .error(errorMessage.message)
              .then(props.clearReportFileError())
          );
        }
      };
      error();
    }

    if (!edit && !reportFileCreated && props.error === null) {
      //filling fields with default values for new reportFiles
      form.setFieldsValue({
        report: props.location.state.report,
        reportname: props.location.state.reportname,
        type: props.location.state.type,
      });
    }

    if (
      edit &&
      !reportFileCreated &&
      props.reportFile === null &&
      props.error === null
    ) {
      props.getReportFile(props.history, props.match.params.id).then((data) => {
        if (data) {
          form.setFieldsValue({
            filename: data.filename,
            description: data.description,
            type: data.type,
            report: data.report,
            reportname: data.reportname,
          });
        } else {
          setEdit(false);
        }
      });
    }

    if (reportFileCreated) {
      if (edit) {
        message.info(intl.formatMessage({ id: "label.reportFile.updated" }));
      } else {
        form.resetFields();
        props.location.state.type = "associated";
        message.info(intl.formatMessage({ id: "label.reportFile.created" }));
      }
      setReportFileCreated(false);
    }

    setAcceptFileExtensions(() => {
      return form.getFieldValue("type") === "report" ? ".rmd,.html,.htm" : null;
    });
    // eslint-disable-next-line
  }, [props.error, edit, reportFileCreated]);

  return (
    <Layout className="site-layout">
      <Content style={{ margin: "0 16px" }}>
        <Breadcrumb style={{ margin: "10px 0" }}>
          <Breadcrumb.Item>
            <HomeOutlined />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>
              <FormattedMessage
                id="menu.configuration"
                defaultMessage="Configuration"
              />
            </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>
              <FormattedMessage id="menu.reports" defaultMessage="Reports" />
            </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>
              <FormattedMessage
                id="menu.reportFiles"
                defaultMessage="Report Files"
              />
            </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>
              {edit ? (
                <FormattedMessage id="label.edit" defaultMessage="Edit" />
              ) : (
                <FormattedMessage id="label.new" defaultMessage="New" />
              )}
            </span>
          </Breadcrumb.Item>
        </Breadcrumb>
        <div
          className="site-layout-background"
          style={{ padding: 24, minHeight: 360 }}
        >
          <Card
            type="inner"
            title={
              edit ? (
                <FormattedMessage
                  id="label.reportFile.edit-reportFile"
                  defaultMessage="Edit Report File"
                />
              ) : (
                <FormattedMessage
                  id="label.reportFile.create-reportFile"
                  defaultMessage="Create a Report File"
                />
              )
            }
            extra={
              <Link
                to={
                  props.location.state?.fromReportFiles
                    ? `/configuration/reports/reportFiles`
                    : {
                        state: {
                          creatingNewReport:
                            props.location.state.creatingNewReport,
                        },
                        pathname: `/configuration/reports/${props.location.state.report}`,
                      }
                }
              >
                <FormattedMessage id="label.return" defaultMessage="Return" />
              </Link>
            }
          >
            <Form
              layout="horizontal"
              name="reportFile_form"
              size="large"
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 14 }}
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label={intl.formatMessage({ id: "label.reportFile.filename" })}
              >
                <label htmlFor="fileElem" className="ant-btn ant-btn-primary">
                  {intl.formatMessage({ id: "label.reportFile.choose" })}
                </label>
                <Form.Item
                  name="filename"
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: "msg.input-required-filename",
                      }),
                    },
                  ]}
                  noStyle
                >
                  <Input type={"text"} autoComplete={"off"} readOnly={true} />
                </Form.Item>
              </Form.Item>
              <Form.Item
                name="uploadedFile"
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: "msg.input-required-uploadedFile",
                    }),
                  },
                ]}
                hidden
                noStyle
              >
                <Input
                  type="file"
                  id="fileElem"
                  accept={acceptFileExtensions}
                  onChange={handleFileElemChange}
                />
              </Form.Item>
              <Form.Item
                name="description"
                label={intl.formatMessage({
                  id: "label.reportFile.description",
                })}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: "msg.input-required-description",
                    }),
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
              <Form.Item
                name="type"
                label={intl.formatMessage({ id: "label.reportFile.type" })}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: "msg.input-required-type",
                    }),
                  },
                ]}
              >
                <Radio.Group disabled>
                  <Radio value={"report"}>
                    <FormattedMessage id="label.reportFile.type.report" />
                  </Radio>
                  <Radio value={"associated"}>
                    <FormattedMessage id="label.reportFile.type.associated" />
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name={"report"}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: "msg.input-required-report",
                    }),
                  },
                ]}
                hidden
              >
                <Input hidden />
              </Form.Item>
              <Form.Item
                label={intl.formatMessage({ id: "label.reportFile.report" })}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: "msg.input-required-report-name",
                    }),
                  },
                ]}
                name={"reportname"}
              >
                <Input disabled={true} />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={props.loading}
                >
                  {edit ? (
                    <FormattedMessage
                      id="label.update"
                      defaultMessage="Update"
                    />
                  ) : (
                    <FormattedMessage
                      id="label.submit"
                      defaultMessage="Submit"
                    />
                  )}
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </div>
      </Content>
      <Footer style={{ textAlign: "center" }}>
        ©{new Date().getFullYear()}
      </Footer>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  loading: selectLoading(state),
  error: selectError(state),
  reportFile: selectCurrentReportFile(state),
});

export default connect(mapStateToProps, {
  addReportFile,
  getReportFile,
  editReportFile,
  clearReportFileError,
})(ReportFileForm);
