import axiosInstance from "./axiosInstance";
import { USER_ACTION_TYPES } from "../redux/typesAction";

export const getUserRegions = (history) => async (dispatch) => {
  try {
    // Send request and get data
    const { data } = await axiosInstance(history, dispatch).get(
      "/geolevel/user_regions"
    );
    dispatch({
      type: USER_ACTION_TYPES.GET_GEO_REGIONS,
      payload: data,
    });
    return data["geoRegions"];
  } catch (err) {
    await sendErrorToConsole(err);
  }
};

export const getUserGeoArea = (history, dispatch) => async (dispatch) => {
  try {
    // Send request and get data
    const { data } = await axiosInstance(history, dispatch).get(
      "/geolevel/user_geo_area"
    );
    dispatch({
      type: USER_ACTION_TYPES.GET_GEO_AREA,
      payload: data,
    });
    return data;
  } catch (err) {
    await sendErrorToConsole(err);
  }
};

export const getUserRegistrationRegions = (history) => async (dispatch) => {
  try {
    // Send request and get data
    const { data } = await axiosInstance(history, dispatch).get("/regions");
    return data;
  } catch (err) {
    await sendErrorToConsole(err);
  }
};
export const getReportShared =
  (history, reportId, sharedToken) => async (dispatch) => {
    try {
      // Send request and get data
      const { data } = await axiosInstance(history, dispatch, sharedToken).get(
        `/report/shared/${reportId}`
      );
      return data;
    } catch (err) {
      await sendErrorToConsole(err);
    }
  };

export const getGuide = (history) => async (dispatch) => {
  try {
    // Send request and get data
    const { data } = await axiosInstance(history, dispatch).get(`/guide`);
    return data;
  } catch (err) {
    await sendErrorToConsole(err);
  }
};

export const getDashboard = (history) => async (dispatch) => {
  try {
    // Send request and get data
    const { data } = await axiosInstance(history, dispatch).get("/dashboard");
    return data;
  } catch (err) {
    await sendErrorToConsole(err);
  }
};

/**
 * This function gets the current user regions by levels.
 * @param idLevel
 * @returns {Function}
 */
export const getUserRegionsByLevels = (idLevel) => async () => {
  try {
    // Send request and get data
    const { data } = await axiosInstance(null, null).get(
      `/geolevel/user_regions/${idLevel}`
    );
    return data["geoRegions"];
  } catch (err) {
    await sendErrorToConsole(err);
  }
};

export const getRegionsByLevelAndLevelUp = (idLevel, levelup) => async () => {
  try {
    // Send request and get data
    if (idLevel === 0) levelup = null;

    const { data } = await axiosInstance(null, null).get(
      `/geolevel/get_regions/${idLevel}/${levelup}`
    );
    return data;
  } catch (err) {
    await sendErrorToConsole(err);
  }
};

export const fetchRegionsData =
  (idLevel, returnCase, filters = {}, setFetchRegionsStatus) =>
  async () => {
    try {
      //validating arguments
      if (idLevel === undefined || idLevel === null) {
        const msg =
          `Unexpected error: Argument 'idLevel' cannot be undefined or null` +
          ` in function 'fetchRegionsData'. Please, contact application's admin!`;
        throw new Error(msg);
      }
      if (!returnCase) {
        const msg =
          `Unexpected error: Argument 'returnCase' cannot be undefined or null` +
          ` in function 'fetchRegionsData'. Please, contact application's admin!`;
        throw new Error(msg);
      }

      // Send request and get data
      //query parameters
      let queryParameters = {};

      let filterCases = [];

      if (filters.hasOwnProperty("parent")) {
        filterCases.push("parent");
        queryParameters["parent"] = filters.parent;
      } else {
        const msg =
          `Unexpected error: Filters must include the key 'parent' in function 'fetchRegionsData'. ` +
          `Please, contact application's admin!`;
        throw new Error(msg);
      }
      if (filters.hasOwnProperty("referenceRegionsFilter")) {
        filterCases.push("referenceRegionsFilter");
        queryParameters["referenceRegionsFilter"] =
          filters.referenceRegionsFilter
            .map((regionData) => JSON.stringify(regionData))
            .join("___");
      } else {
        const msg =
          `Unexpected error: Filters must include the key 'referenceRegionsFilter' in function 'fetchRegionsData'. ` +
          `Please, contact application's admin!`;
        throw new Error(msg);
      }

      if (filters.hasOwnProperty("strategy")) {
        queryParameters["strategy"] = filters.strategy;
      } else {
        const msg =
          `Unexpected error: Filters must include the key 'strategy' in function 'fetchRegionsData'. ` +
          `Please, contact application's admin!`;
        throw new Error(msg);
      }

      if (filters.hasOwnProperty("loadRegionsFilter")) {
        filterCases.push("loadRegionsFilter");
        queryParameters["loadRegionsFilter"] = filters.loadRegionsFilter
          .map((regionData) => JSON.stringify(regionData))
          .join("___");
      }

      if (filters.hasOwnProperty("editModeRegionsFilter")) {
        filterCases.push("editModeRegionsFilter");
        queryParameters["editModeRegionsFilter"] = filters.editModeRegionsFilter
          .map((regionData) => JSON.stringify(regionData))
          .join("___");
      }

      switch (filters.parent) {
        case "DataAnalysisForm":
          if (filters.hasOwnProperty("viewModeRegionsFilter")) {
            filterCases.push("viewModeRegionsFilter");
            queryParameters["viewModeRegionsFilter"] =
              filters.viewModeRegionsFilter
                .map((regionData) => JSON.stringify(regionData))
                .join("___");
          }
          break;
        default:
      }

      let formData = new FormData();
      // const paramsKeys = Object.keys(queryParameters);
      for (const key in queryParameters) {
        // const actualKey = paramsKeys[key];
        formData.append(key, queryParameters[key]);
      }

      const { data } = await axiosInstance(null, null).post(
        `/geolevel/get_regions_data/${idLevel}/${returnCase}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setFetchRegionsStatus(() => {
        return {
          parent: filters.parent,
          status: "success",
          case: `fetchRegionsData - [${filterCases.join(", ")}]`,
          description: "Regions data was successfully retrieved from server!",
          idLevelMax: idLevel,
          returnCase: returnCase,
          params: queryParameters,
          data: data,
        };
      });
      return data;
    } catch (err) {
      setFetchRegionsStatus(() => {
        return {
          parent: filters.parent,
          status: "failed",
          case: `fetchRegionsData - error`,
          description: "Error retrieving regions data from server!",
          idLevelMax: idLevel,
          returnCase: returnCase,
          filters: filters,
          error: err,
        };
      });
      await sendErrorToConsole(err);
    }
  };

export const fetchLevelsData =
  (returnCase, filters = {}, setFetchLevelsStatus) =>
  async () => {
    try {
      if (!returnCase) {
        const msg =
          `Unexpected error: Argument 'returnCase' cannot be undefined or null` +
          ` in function 'fetchLevelsData'. Please, contact application's admin!`;
        throw new Error(msg);
      }

      // setting parameters
      let queryParameters = {};

      let filterCases = [];

      if (filters.hasOwnProperty("referenceRegionsFilter")) {
        filterCases.push("referenceRegionsFilter");
        queryParameters["referenceRegionsFilter"] =
          filters.referenceRegionsFilter
            .map((regionData) => JSON.stringify(regionData))
            .join("___");
      } else {
        const msg =
          `Unexpected error: Filters must include the key 'referenceRegionsFilter' in function 'fetchLevelsData'. ` +
          `Please, contact application's admin!`;
        throw new Error(msg);
      }

      if (filters.hasOwnProperty("parent")) {
        filterCases.push("parent");
        queryParameters["parent"] = filters.parent;
      } else {
        const msg =
          `Unexpected error: Filters must include the key 'parent' in function 'fetchLevelsData'. ` +
          `Please, contact application's admin!`;
        throw new Error(msg);
      }

      let formData = new FormData();
      // const paramsKeys = Object.keys(queryParameters);
      for (const key in queryParameters) {
        // const actualKey = paramsKeys[key];
        formData.append(key, queryParameters[key]);
      }
      // Send request and get data
      const { data } = await axiosInstance(null, null).post(
        `/geolevel/get_levels_data/${returnCase}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setFetchLevelsStatus(() => {
        return {
          parent: filters.parent,
          status: "success",
          case: `fetchLevelsData - [${filterCases.join(", ")}]`,
          description: "Levels data was successfully retrieved from server!",
          returnCase: returnCase,
          params: queryParameters,
          data: data,
        };
      });
      return data;
    } catch (err) {
      setFetchLevelsStatus(() => {
        return {
          parent: filters.parent,
          status: "failed",
          case: `fetchLevelsData - error`,
          description: "Error retrieving levels data from server!",
          returnCase: returnCase,
          filters: filters,
          error: err,
        };
      });
      await sendErrorToConsole(err);
    }
  };

/** TODO: CLEAN THIS FUNCTION. IT IS NOT FURTHER REQUIRED AFTER REMOVING
 * OLD VERSIONS OF FormItemsLocations component.
 * Gets the GeoLevels
 * @returns {Function}
 */
export const getGeoLevels = () => async () => {
  try {
    // setting parameters
    let queryParameters = {};
    queryParameters["offset"] = 0;
    queryParameters["max"] = 1000; //high enough to get all
    // Spread the parameters
    const params = {
      ...queryParameters,
    };
    // Send request and get data
    const { data } = await axiosInstance(null, null).get(`/geolevel`, {
      params,
    });
    return data;
  } catch (err) {
    await sendErrorToConsole(err);
  }
};

/**
 * Gets all Regions
 * @returns {Function}
 */
export const getAllRegions = () => async () => {
  try {
    // Send request and get data
    const { data } = await axiosInstance(null, null).get(
      `/geolevel/all_regions`
    );
    return data;
  } catch (err) {
    await sendErrorToConsole(err);
  }
};

/**
 * Gets Region Details
 * @returns {Function}
 */
export const getLevelsByForm = (formId) => async () => {
  try {
    // Send request and get data
    const { data } = await axiosInstance(null, null).get(
      `/geolevel/get_levels_by_form/${formId}`
    );
    return data;
  } catch (err) {
    await sendErrorToConsole(err);
  }
};

/**
 * Gets Region Details
 * @returns {Function}
 */
export const getRegionDetailsByForm = (formId) => async () => {
  try {
    // Send request and get data
    const { data } = await axiosInstance(null, null).get(
      `/geolevel/get_region_by_form/${formId}`
    );
    return data;
  } catch (err) {
    await sendErrorToConsole(err);
  }
};

/**
 * Gets Region Details
 * @returns {Function}
 */
export const getRegionDetailsByLayer = (layerId) => async () => {
  try {
    // Send request and get data
    const { data } = await axiosInstance(null, null).get(
      `/geolevel/get_region_by_layer/${layerId}`
    );
    return data;
  } catch (err) {
    await sendErrorToConsole(err);
  }
};

export const getStatsPlots = (values) => async () => {
  try {
    // Send request and get data
    const { data } = await axiosInstance(null, null).post(
      `/analysis/stats_plots`,
      values
    );
    return data;
  } catch (err) {
    await sendErrorToConsole(err);
  }
};

export const getStatsPointMap = (values) => async () => {
  try {
    // Send request and get data
    const { data } = await axiosInstance(null, null).post(
      `/analysis/stats_point_map`,
      values
    );
    return data;
  } catch (err) {
    await sendErrorToConsole(err);
  }
};

export const getDataForAnalysis = (id) => async () => {
  try {
    // Send request and get data
    const { data } = await axiosInstance(null, null).get(
      `/dataAnalysis/getData/${id}`
    );
    return data;
  } catch (err) {
    await sendErrorToConsole(err);
  }
};

export const getPopupDataPointMap = (formId, dataId) => async () => {
  try {
    // Send request and get data
    const { data } = await axiosInstance(null, null).get(
      `/form/${formId}/data/${dataId}`
    );
    return data;
  } catch (err) {
    await sendErrorToConsole(err);
  }
};

export const getPopupData =
  (analysisId, dataId, seriesIndex = null) =>
  async () => {
    try {
      //params
      const params = {
        dataId: dataId,
      };

      if (seriesIndex !== null) {
        params["seriesIndex"] = seriesIndex;
      }

      // Send request and get data
      const { data } = await axiosInstance(null, null).get(
        `/dataAnalysis/getData/${analysisId}`,
        { params }
      );
      return data;
    } catch (err) {
      await sendErrorToConsole(err);
    }
  };

export const getPointRegion = (analysisId, coordinates) => async () => {
  try {
    //params
    const params = {
      lng: coordinates[0],
      lat: coordinates[1],
    };

    // Send request and get data
    const { data } = await axiosInstance(null, null).get(
      `/dataAnalysis/getPointRegion/${analysisId}`,
      { params }
    );
    return data;
  } catch (err) {
    await sendErrorToConsole(err);
  }
};

export const getForms = (history) => async (dispatch) => {
  try {
    // Prepare all query parameters
    let queryParameters = {};

    queryParameters["sort"] = "name";
    queryParameters["order"] = "asc";

    queryParameters["max"] = 999999999;

    // Spread the parameters
    const params = {
      ...queryParameters,
    };

    const { data } = await axiosInstance(history, dispatch).get("/form", {
      params,
    });
    return data["forms"];
    // Update state
  } catch (err) {
    await sendErrorToConsole(err);
  }
};

export const getStaffByProject = (history, project) => async (dispatch) => {
  try {
    // Prepare all query parameters
    let queryParameters = {};

    queryParameters["project"] = project;
    queryParameters["sort"] = "name";
    queryParameters["order"] = "asc";

    queryParameters["max"] = 999999999;

    // Spread the parameters
    const params = {
      ...queryParameters,
    };

    const { data } = await axiosInstance(history, dispatch).get("/staff", {
      params,
    });
    return data["staff"];
    // Update state
  } catch (err) {
    await sendErrorToConsole(err);
  }
};

export const getAlertContactByProject =
  (history, project, type) => async (dispatch) => {
    try {
      // Prepare all query parameters
      let queryParameters = {};

      queryParameters["project"] = project;
      queryParameters["sort"] = "name";
      queryParameters["order"] = "asc";
      queryParameters["max"] = 999999999;

      if (type === "notifications") {
        queryParameters["notifications"] = 1;
      } else if (type === "subscriptions") {
        queryParameters["subscriptions"] = 1;
      }

      // Spread the parameters
      const params = {
        ...queryParameters,
      };

      const { data } = await axiosInstance(history, dispatch).get(
        "/alertContact",
        { params }
      );
      return data["alertContacts"];
      // Update state
    } catch (err) {
      await sendErrorToConsole(err);
    }
  };

export const changeLanguage = (history, lang) => async (dispatch) => {
  try {
    let queryParameters = {};
    queryParameters["lang"] = lang;
    const params = {
      ...queryParameters,
    };

    // Send request and get data
    const { data } = await axiosInstance(history, dispatch).get("/languages", {
      params,
    });
    return data;
  } catch (err) {
    await sendErrorToConsole(err);
  }
};

const sendErrorToConsole = async (err) => {
  let error = await err?.response?.data;
  error = error ? error : err;
  console.log(error);
};

export const DATEFORMAT = "YYYY-MM-DD";

export const getGeoAreaByFormId = (history, formId) => async (dispatch) => {
  try {
    // Send request and get data
    const { data } = await axiosInstance(history, dispatch).get(
      `/geolevel/form_geo_area/${formId}`
    );
    return data;
  } catch (err) {
    console.log(err);
  }
};
