import { message } from "antd";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export const debugLog = (label, value, componentName) => {
  console.log(`debugLog--${componentName}---${label}:`, value);
};

export const useHasRole = (role) => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const isRole = currentUser.permissions.includes(role);
  return isRole;
};

export const useHas_ROLE_ADMIN = () => {
  const has_ROLE_ADMIN = useHasRole("ROLE_ADMIN");
  return has_ROLE_ADMIN;
};

export const useHas_ROLE_PERMISSION_DEBUG_VIEW = () => {
  const has_ROLE_PERMISSION_DEBUG_VIEW = useHasRole(
    "ROLE_PERMISSION_DEBUG_VIEW"
  );
  return has_ROLE_PERMISSION_DEBUG_VIEW;
};

export const useDebug = ({ vars, componentName }) => {
  const has_ROLE_PERMISSION_DEBUG_VIEW = useHas_ROLE_PERMISSION_DEBUG_VIEW();

  //initial log
  useEffect(function useEffect_initialLine() {
    if (!has_ROLE_PERMISSION_DEBUG_VIEW) return;
    
    const label = "Initial line";
    const value = "";
    debugLog(label, value, componentName);
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [componentName]);

  //printing all the variables given in props.vars
  vars?.forEach((varItem) => {
    const requiredKeys = ["name", "value", "dependencies"];
    const actualKeys = Object.keys(varItem);
    const isOk = requiredKeys.every((requiredKey) =>
      actualKeys.includes(requiredKey)
    );

    if (!isOk) {
      const errorMsg = [
        `Something went wrong!`,
        `Error's details:`,
        `The 'varItem' object in 'useDebug' hook does not contain all the required keys.`,
        `Actual keys: [${actualKeys.join(", ")}]`,
        `Required keys: [${requiredKeys.join(", ")}]`,
      ].join(" ");
      console.error(errorMsg);
      message.error(errorMsg);
      return;
    }

    if (!Array.isArray(varItem.dependencies)) {
      const errorMsg = [
        `Something went wrong!`,
        `Error's details:`,
        `The 'varItem.dependencies' in 'useDebug' hook is not an array.`,
        `It happened for variable name: '${varItem.name}'.`,
      ].join(" ");
      console.error(errorMsg, varItem.dependencies);
      message.error(errorMsg);
      return;
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(
      () => {
        if (has_ROLE_PERMISSION_DEBUG_VIEW && varItem.shouldLog) {
          debugLog(varItem.name, varItem.value, componentName);          
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      varItem.dependencies
    );
  });
};
